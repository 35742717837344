import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { Account, Project } from '../../../helpers/GlobalInterfaces';
import AuthService from '../../../helpers/AuthService';
import { getLink, links } from '../../../helpers/urls';
import showNotification from '../../../components/extras/showNotification';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import { businessMenu, dashboardMenu, settingsMenu } from '../../../menu';
import { checkPermission } from '../../../helpers/global_helpers';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [live, setLive] = useState<Project | null>();

	const [userData, setUserData] = useState<Account | null>();

	const userDataRaw = AuthService().getUserData();

	useEffect(() => {
		if (userDataRaw != null) {
			setUserData(JSON.parse(userDataRaw));
		}
	}, [userDataRaw]);

	useEffect(() => {
		if (userData && userData.id > 0) {
			setLive(
				userData
					.companies!.find(
						(company) => company.id === Number(AuthService().getLiveCompany()),
					)!
					.projects.find(
						(project) => project.id === Number(AuthService().getLiveProject()),
					)!,
			);
		}
	}, [userData]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			is_live: !!(live && live.id > 0 && live.is_live),
		},
		onSubmit: async () => {
			const response = await AuthService().putRequest(
				getLink(
					`${links.pages.changeProjectStatus}/${live!.id}?company_id=${live!.company_id}`,
				),
				{},
			);
			if (response.status) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Update Successfully</span>
					</span>,
					response.message,
				);
				await AuthService().getUserDataBg(true);
			} else {
				swal('Oops!', response.message, 'error');
			}
		},
	});

	return (
		<Aside>
			{userData && userData.id > 0 && userData.companies && userData.companies.length > 0 && (
				<>
					<AsideHead>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					</AsideHead>
					<AsideBody>
						{userData.companies.length > 1 && (
							<Dropdown className='w-100' setIsOpen={() => {}}>
								<DropdownToggle hasIcon>
									<Button
										className='w-100 mb-2'
										icon='Folder2Open'
										color='primary'
										isOutline
										rounded={0}>
										{AuthService().getLiveCompany() === ''
											? userData.companies[0].name
											: userData.companies.find(
													(company) =>
														company.id ===
														Number(AuthService().getLiveCompany()),
												)?.name}
									</Button>
								</DropdownToggle>
								<DropdownMenu>
									{userData.companies.map((item) => {
										return (
											<DropdownItem key={item.id}>
												<Button
													onClick={async () => {
														if (
															item.id !==
															Number(AuthService().getLiveCompany())
														) {
															AuthService().setLiveCompany(item.id);
															AuthService().setLiveProject(
																item.projects[0].id,
															);
															await AuthService().getUserDataBg(true);
														}
													}}>
													{item.name}
												</Button>
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</Dropdown>
						)}
						<Navigation menu={dashboardMenu} id='aside-dashboard' />
						<NavigationLine />
						<Navigation menu={businessMenu} id='aside-business' />
						<NavigationLine />
						<Navigation menu={settingsMenu} id='aside-settings' />
						<NavigationLine />
					</AsideBody>
					<AsideFoot>
						{checkPermission(
							'projects',
							userData.companies.find(
								(company) => company.id === Number(AuthService().getLiveCompany()),
							)!,
						) &&
							live &&
							live.id > 0 && (
								<nav aria-label='aside-bottom-menu'>
									<div className='navigation'>
										<div
											role='presentation'
											className='navigation-item cursor-pointer'
											onClick={() => {
												formik.handleSubmit();
											}}
											data-tour='documentation'>
											<span className='navigation-link navigation-link-pill'>
												<span className='navigation-link-info'>
													<Icon
														icon={
															live.is_live ? 'ToggleOn' : 'ToggleOff'
														}
														color={live.is_live ? 'success' : 'danger'}
														className='navigation-icon'
													/>
													<span className='navigation-text'>
														{live.is_live
															? 'Live Mode'
															: 'Sandbox Mode'}
													</span>
												</span>
												<span className='navigation-link-extra'>
													<Icon
														icon='Circle'
														className={classNames(
															'navigation-notification',
															'text-success',
															'animate__animated animate__heartBeat animate__infinite animate__slower',
														)}
													/>
												</span>
											</span>
										</div>
									</div>
								</nav>
							)}
						<User />
					</AsideFoot>
				</>
			)}
		</Aside>
	);
};

export default DefaultAside;
