// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export const datFormat = (date: number) => {
	return moment(date).fromNow();
};

export const datFormat2 = (date: number) => {
	return moment(date).format('Do MMMM, YYYY');
};

export const datFormat3 = (date: number) => {
	return moment(date).format('Do MMMM, YYYY hh:mma');
};

export const datFormat4 = (date: number) => {
	return moment(date).format('hh:mm A');
};

export function capitalizeFirstLetter(str: string) {
	return `${str}`.replace(/^([a-z])|\s+([a-z])/g, ($1) => {
		return $1.toUpperCase();
	});
}

export function formatAmount(amount: number, currency: string = 'ngn') {
	return amount.toLocaleString(currency.toLowerCase() === 'usd' ? 'en-US' : 'en-NG', {
		style: 'currency',
		currency: currency.toUpperCase(),
	});
}

export function ordinalSuffixOf(i: number) {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return `${i}st`;
	}
	if (j === 2 && k !== 12) {
		return `${i}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}

export const formatNumberWithCommasRegex = (num: number) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
