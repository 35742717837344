import { Company } from './GlobalInterfaces';

export function checkPermission(checking: string, company: Company) {
	let response = false;
	if (
		company &&
		company.roles_company &&
		company.roles_company[0].user_role_permissions.length > 0
	) {
		const thePermissions = company.roles_company[0].user_role_permissions;
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < thePermissions.length; i++) {
			if (thePermissions[i].name === checking) {
				response = true;
				break;
			}
		}
	} else {
		response = false;
	}

	return response;
}

export function formatDate(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

export function getMonthStartAndEnd(dateStr: string) {
	const [month, year] = dateStr.split('-').map(Number);

	// Create Date object for the first day of the month
	const firstDay = new Date(year, month - 1, 1);

	// Create Date object for the last day of the month
	const lastDay = new Date(year, month, 0);

	// Format the dates
	const formattedFirstDay = formatDate(firstDay);
	const formattedLastDay = formatDate(lastDay);

	return { formattedFirstDay, formattedLastDay };
}

export function getMonthAndYear(dateStr: string) {
	const [month, year] = dateStr.split('-').map(Number);

	// Create Date object for the first day of the month
	const firstDay = new Date(year, month - 1, 1);

	// Create Date object for the last day of the month
	const lastDay = new Date(year, month, 0);

	// Format the dates
	const formattedFirstDay = formatDate(firstDay);
	const formattedLastDay = formatDate(lastDay);

	return { formattedFirstDay, formattedLastDay };
}

export const getMonthDifference = (startDate: Date, endDate: Date) => {
	const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
	const monthsDifference = endDate.getMonth() - startDate.getMonth();

	return yearsDifference * 12 + monthsDifference;
};

export const getMonthListParams = () => {
	const launchDate = new Date('2022-01-01');
	const currentDate = new Date();
	const val = getMonthDifference(launchDate, currentDate);
	const resps = [];
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < val; i++) {
		const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
		resps.push({
			value: `${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`,
			label: date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
		});
	}
	return resps;
};

export const svgToDataUri = (svg: string) => {
	// URI encode the SVG string
	const encodedSvg = encodeURIComponent(svg).replace(/'/g, '%27').replace(/"/g, '%22');

	// Construct the data URI
	return `data:image/svg+xml;charset=UTF-8,${encodedSvg}`;
};

export function getFileExtensionFromBase64(base64String: string): string | null {
	// Regular expression to match the data URL scheme
	const match = base64String.match(/^data:(.*);base64,/);
	if (match && match[1]) {
		// Extract the media type (e.g., image/jpeg, image/png)
		const mediaType = match[1];
		// Split the media type by '/' and return the second part as the file extension
		const extension = mediaType.split('/')[1];
		return extension;
	}
	return null;
}

export const convertFileToBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			resolve(reader.result); // Base64 string
		};

		reader.onerror = (error) => {
			reject(error); // Handle error
		};

		reader.readAsDataURL(file); // Convert file to Base64
	});
};

export function isValidURL(url: string): boolean {
	try {
		// eslint-disable-next-line no-new
		new URL(url);
		return true;
	} catch (err) {
		return false;
	}
}
