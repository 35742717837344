import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import AuthService from '../../../helpers/AuthService';
import { Account, Company } from '../../../helpers/GlobalInterfaces';
import CommonHeaderRight from './CommonHeaderRight';
import Avatar from '../../../components/Avatar';
import MaleAvatar from '../../../assets/img/avatar/male.jpg';
import FemaleAvatar from '../../../assets/img/avatar/female.jpg';
import { settingsMenu } from '../../../menu';

const DashboardHeader = () => {
	const [userData, setUserData] = useState<Account>();
	const [liveCompany, setLiveCompany] = useState<Company>();
	const isUserLogged = AuthService().isUserLogged();

	const { darkModeStatus } = useDarkMode();

	useEffect(() => {
		if (isUserLogged) {
			const data = AuthService().getUserData();
			setUserData(JSON.parse(data!));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (userData && userData.id > 0) {
			setLiveCompany(
				userData.companies!.find(
					(company) => company.id === Number(AuthService().getLiveCompany()),
				)!,
			);
		}
	}, [userData]);

	const navigate = useNavigate();
	return (
		<Header>
			<HeaderLeft>
				{userData && userData.id > 0 && liveCompany && liveCompany.id > 0 ? (
					<div className='d-flex align-items-center'>
						<div className='row g-4'>
							<div className='col-md-auto'>
								<div
									className={classNames('fs-4', 'text-uppercase', {
										'text-dark': !darkModeStatus,
									})}>
									{liveCompany && liveCompany.name}
								</div>
								<div className='fs-6'>
									{' '}
									{(liveCompany && liveCompany.business_registration) ??
										'Pending Completion'}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div />
				)}
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderRight
					userData={userData!}
					company={liveCompany!}
					afterChildren={
						<div className='col d-flex align-items-center'>
							{userData && userData.id > 0 && liveCompany && liveCompany.id > 0 && (
								<Dropdown className='d-inline-block' setIsOpen={() => {}}>
									<DropdownToggle hasIcon={false}>
										<div className='col d-flex align-items-center'>
											<div className='me-3'>
												<Avatar
													src={
														userData.id > 0 &&
														userData.gender &&
														userData.gender.toLowerCase() === 'female'
															? FemaleAvatar
															: MaleAvatar
													}
													size={48}
													color='primary'
												/>
											</div>
											<div>
												<div className='fw-bold fs-6 mb-0'>
													{userData.name.toUpperCase()}
												</div>
												<div className='text-muted'>
													<small>
														{liveCompany.pivot.title.toUpperCase()}
													</small>
												</div>
											</div>
										</div>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												icon={settingsMenu.profile.icon}
												tag='a'
												onClick={() => navigate(settingsMenu.profile.path)}>
												Profile
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												icon='PowerSettingsNew'
												tag='a'
												onClick={() => AuthService().logoutUser()}>
												Logout
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}
						</div>
					}
				/>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
