import React, { FC } from 'react';
import PropTypes from 'prop-types';
import LogoLight from '../assets/img/logo/logo-light.png';
import LogoDark from '../assets/img/logo/logo.png';

interface ILogoProps {
	width?: number;
	height?: number;
	isLight?: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, isLight }) => {
	const aspectRatio = 3353 / 889;

	const calculatedWidth = height !== 854 && !!height ? height * aspectRatio : width;
	const calculatedHeight = width !== 3353 && !!width ? width / aspectRatio : height;

	const finalWidth = calculatedWidth || 3353;
	const finalHeight = calculatedHeight || 889;
	return isLight ? (
		<img src={LogoLight} width={finalWidth} height={finalHeight} alt='MeVerify' />
	) : (
		<img src={LogoDark} width={finalWidth} height={finalHeight} alt='MeVerify' />
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	isLight: PropTypes.bool,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	isLight: true,
};

export default Logo;
